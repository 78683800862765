.servicesCloudConatiner{
    /* background-color: rgba(238, 247, 253, 0.295) ; */
    background: linear-gradient(to right,   rgb(242, 248, 249) , rgb(246, 249, 251),rgb(236, 249, 252) ,rgb(230, 253, 255));

}
.servicesCloudBox{
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 5px rgb(185, 199, 230);
}
.servicesCloudBox:hover{
 
    box-shadow: 1px 1px 50px rgb(185, 199, 230);
}

.servicesCloudBox p{
font-size: 1.1rem;
}