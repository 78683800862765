@font-face {
    font-family: 'outfit'; /* Use your font name */
    /* src: url('../../assets/fonts/Outfit-Light.ttf') format('truetype');
    src: url('../../assets/fonts/Outfit-Medium.ttf') format('truetype');
    src: url('../../assets/fonts/Outfit-Regular.ttf') format('truetype');
    src: url('../../assets/fonts/Outfit-SemiBold.ttf') format('truetype');
    src: url('../../assets/fonts/Outfit-Black.ttf') format('truetype');
    src: url('../../assets/fonts/Outfit-ExtraBold.ttf') format('truetype');
    src: url('../../assets/fonts/Outfit-ExtraLight.ttf') format('truetype');
    src: url('../../assets/fonts/Outfit-Thin.ttf') format('truetype'); */
    src: url('../../assets/fonts/Outfit-Bold.ttf') format('truetype');  
    font-weight: 500;
    font-style:normal;
}

@font-face {
    font-family: 'outfit-semi';
    src: url('../../assets/fonts/Outfit-SemiBold.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
 
}
@font-face {
    font-family: 'outfit-medium';
    src: url('../../assets/fonts/Outfit-Medium.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
 
}

@font-face {
    font-family: 'play-write';
    src: url('../../assets/fonts/PlaywriteITModerna-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
 
}
