.servicesMangeServiceConatiner{
    /* background-color: rgba(238, 247, 253, 0.295) ; */
    /* background: linear-gradient(to right,   rgb(223, 247, 244) , rgb(238, 254, 251),rgb(236, 252, 247) ,rgb(209, 252, 238)); */

}
.servicesMangeServiceBox{
    background-color: white;
    background-color: #EDF1F3;
    border-bottom: 5px solid;

}