.HomeContainerTitle{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 10%;
   /* color: rgb(245, 253, 255); */
}
/* Wrapper for aligning items in the center */
.custom-carousel .carousel-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Individual carousel div */
  .carousel-div {
    width: 30%;
    height: 200px;
    background-color: #f1f1f1;
    border-radius: 10px;
    text-align: center;
    line-height: 200px;
    font-size: 1.5rem;
    font-weight: bold;
    transition: transform 0.5s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 0 10px;
  }
  
  .carousel-div:hover {
    transform: scale(1.1);
    background-color: #d1d1d1;
  }
  
  /* Responsive for mobile: show only one div per slide */
  @media (max-width: 767px) {
    .carousel-div {
      width: 100%;
    }
  
    .carousel-item-wrapper {
      justify-content: center;
    }
  }
  
  /* Responsive for tablet: show only one div per slide */
  @media (min-width: 768px) and (max-width: 991px) {
    .carousel-div {
      width: 100%;
    }
  
    .carousel-item-wrapper {
      justify-content: center;
    }
  }
  
  /* On desktop: still show 3 divs, but slide one by one */
  @media (min-width: 992px) {
    .carousel-div {
      width: 30%;
      color: rgb(145, 222, 222);
    }
  }
  
.HomeContainerAbout,.HomeContainerWhy{
  box-shadow: 1px 1px 4px rgb(211, 211, 231);
}
.HomeContainercarousel{
  background-color: black;
}

.HomeContainerAbout:hover,.HomeContainerWhy:hover{
  transition: 1s;
  /* color: white; */
  padding: 40px;
  /* background-color: rgb(158, 162, 226); */
  background-color: rgb(224, 240, 255);
  /* color: white; */
}

.HomeContainerAboutButton{
  padding: 10px;
  border: none;
  background-color: rgb(148, 69, 174);
  color: white;
  border-radius: 2px;
  background-color: rgb(28, 5, 84);

}
.HomeContainerAboutButton:hover{
  background-color: rgb(148, 69, 174);

}
/* 
.homeCardConatinerHeading{
  box-shadow: 1px 1px 10px rgb(168, 170, 170);
  height: 300px; 
  width: 300px;
  padding-top: 30px;
  z-index: 1;
  background-color: rgb(202, 101, 101);
  background-color: rgb(127, 254, 205);
}

.homeCardConatinerContent{
  box-shadow: 1px 1px 10px rgb(168, 170, 170);
  height: 300px; 
  width: 300px;
  padding: 50px;
  position: relative;
  left: 0px;
  bottom: 190px;
  z-index: 1;
background-color: white;

}

.homeCardConatinerContent:hover{
  
  transition: 2s;
  left: 0px;

  bottom: 0px;

}
.homeCardConatiner{
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items:center;
  background: linear-gradient(to bottom,rgb(255, 255, 108), rgb(184, 244, 154), rgb(51, 215, 227),rgb(220, 86, 232));
  background: linear-gradient(to right,rgb(195, 243, 117), rgb(213, 255, 192), rgb(154, 244, 252),rgb(220, 86, 232));
background-color: red;


}

.homeCard{
height: 450px;
width: 380px;
padding: 0;

}

@media  (max-width: 480px) {
  .homeCardConatinerHeading{
    height: 240px; 
    width: 240px;
    padding:12px;
  }
  
  .homeCardConatinerContent{
    height: 240px; 
    width: 240px;
    padding: 20px;
    bottom: 160px;
   
    
  
  }
  .homeCardConatinerContent:hover{
    left: 0px;

    bottom: 180px;
  }
 .homeCardConatinerHeadingManpower:hover{
  bottom: 160px;

 }

 .homeCardConatiner{
  background: linear-gradient(to right, rgb(241, 255, 234), rgb(222, 248, 249),rgb(254, 234, 255));
}
} */


/* Basic Container Styling */
.card-container {
  /* perspective: 1000px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 390px;
 margin-top: 3%;
}
@media (max-width: 350px) {
  .card-container {
    height: 470px;

  }
}


/* Card Styling */
.card {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  border: 0px;
  
}

/* Rotate Card on Hover */
.card-container:hover .card {
  transform: rotateY(180deg);
}

/* Card Faces */
.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 1.2rem;
  padding: 20px;
  row-gap: 100px;
  
  border-bottom: 10px solid #E16A7C;
  
  
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); */
  ;
}
.card-back{
  height: 100%;
  text-decoration: none

}
/* Front Face Styling */
.card-front {

color: black;
}

/* Back Face Styling */
.card-back {
  background-color: #a691e4;
  transform: rotateY(180deg);
  background-color: #E16A7C;
}



.HomeContainerCards{
  /* background: linear-gradient(to right,   rgba(158, 164, 255, 0.454),rgba(241, 245, 255, 0.628),rgba(219, 216, 255, 0.628)); */
  /* background: linear-gradient(to right,rgb(195, 243, 117), rgb(213, 255, 192), rgb(154, 244, 252),rgb(220, 86, 232)); */
  /* background: linear-gradient(to bottom,rgb(255, 255, 255), rgb(211, 215, 255), rgb(239, 221, 255),rgb(208, 210, 255)); */
background-color: rgba(247, 246, 246, 0.477);
margin: 0px;
}
.HomeContainerCardsHeading{
  font-size: 3rem;
}
@media (max-width: 576px) {
  .HomeContainerCardsHeading {
    font-size: 2rem; /* Small devices */
  }
}


.homeCardBox{
  text-decoration: none;
  color: white;
  

}