.contactHeading h1{
    font-size: 2rem;
    color: black;
    font-family: 'Poppins', sans-serif;
    text-align: start;
    font-weight: 400;
  }
  
  .contactHeading p{
    margin-top: 10px;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
  }
  
  .contactHeading {
    margin-top: 10px;
    text-align: start;
    margin-left: 3%;
    
  }
  
  .contactContainer {
    margin-left: 2%;
    margin-right: 2%;
    display: grid;
    grid-template-columns: 50% 40%;
    column-gap: 3%;
    
  }
  
  .contactImage {
    width: 100%;
  }
  .contactImage img {
    width: 100%;
    }
  
  .contactForm {
    /* background-color: rgb(254, 253, 255); */
    /* border-radius: 10px;
    padding: 20%; */
    max-width: 700px;
    text-align: start;
    /* margin-top: 20px; */
  
    /* box-shadow: 2px 2px 20px rgb(146, 157, 237); */
  }
  
  .contactForm input,.contactForm select,.contactForm textarea{
    width: 100%;
    border: 2px solid grey;
    padding: 10px;
    border-radius: 10px;
    text-align: start;
    margin-top: 2%;
  }
  .contactForm select ,.contactForm textarea{
    padding: 12px;
  }
  
  .contactFormInput {
    margin-top: 5px;
   
  }
  .contactFormInput button {
    background-color: rgb(92, 109, 241); 
    color: #fff;                       
    padding: 10px 20px;                
    border: none;                    
    border-radius: 5px;                
    font-size: 16px;                   
    cursor: pointer;  
    margin-top: 10px;          
  width: 100%;  ;
   
    transition: background-color 0.5s ease; 
  }
  .contactFormInput button:hover {
    background-color: white;
    color: rgb(9, 4, 74);
    border: 2px solid rgb(9, 4, 74);  
  
   }
  
  
  .contactDetails  {
     width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: 35% 30% 30%; 
    column-gap: 2%;
    row-gap: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  
  
   .contactDetailsAddres , .contactDetailsEmail , .contactDetailsPhone {
    border-radius: 10px;
    padding: 10px;
    box-shadow: 2px 2px 20px rgb(141, 151, 225);
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }
  .contactDetailsAddres h3, .contactDetailsEmail h3, .contactDetailsPhone h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  
  }
  
  .contactDetailsAddres a{
    color: red;
  }
  @media (max-width: 800px) {
    .contactContainer, .contactDetails {
      grid-template-columns: 1fr;
    }
    .contactDetails {
      padding: 5%;
    }
    .contactImage img {
      margin-top: 0;
      }
   }